const { SlotsProvider } = require('nano-slots');
const { Helmet } = require('react-helmet');
const { YMInitializer } = require('react-yandex-metrika');
const countries = require('i18n-iso-countries');
const ruCountries = require('i18n-iso-countries/langs/ru.json');

const { NotificationsContainer } = require('@ui/Notifications');

require('./src/shared/styles/style.scss');

exports.wrapRootElement = ({ element }) => {
  countries.registerLocale(ruCountries);
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://www.google.com/" crossOrigin />
        <link rel="preconnect" href="https://www.google.ru/" crossOrigin />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500;600;700&display=swap" />
        <link
          rel="preconnect"
          href="https://stats.g.doubleclick.net"
          crossOrigin
        />
        <link
          rel="preconnect"
          href="https://mc.yandex.ru/webvisor"
          crossOrigin
        />
        <link
          rel="preconnect"
          href="https://www.google-analytics.com/"
          crossOrigin
        />
        <link
          rel="preconnect"
          href="https://www.googletagmanager.com/"
          crossOrigin
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
          integrity="sha512-yHknP1/AwR+yx26cB1y0cjvQUMvEa2PFzt1c9LlS4pRQ5NOTZFWbhBig+X9G9eYW/8m0/4OXNx8pxJ6z57x0dw=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
          media="print"
          onLoad="this.media='all';"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
          integrity="sha512-17EgCFERpgZKcm0j0fEq1YCJuyAWdz9KUtv1EjVuaOz8pDnh/0nZxmU6BBXwaaxqoi9PQXnRWqlcDB027hgv9A=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
          media="print"
          onLoad="this.media='all';"
        />
      </Helmet>
      {element}
    </>
  );
};

exports.wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <YMInitializer
        accounts={[82410397]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
      />
      <NotificationsContainer />
    </>
  );
};

exports.shouldUpdateScroll = ({
  routerProps: { location },
}) => {
  const { pathname } = location;
  // dont scroll on top when change category
  const scrollToTopRoutes = ['/blog/'];
  if (scrollToTopRoutes.includes(pathname)) {
    return false;
  }
};
